@use '../abstracts/variables' as *;

// Global page wrapper
section,
header,
footer,
nav,
article {
  > .wrapper {
    width: $global-wrapper-width;
    margin: 0 auto;
    max-width: calc(100% - 30px);
  }
}
