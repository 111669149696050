// Wrapper width
$global-wrapper-width: 1750px;

// Font Family
$font-family: 'Montserrat', sans-serif;

// Images path
$images-path: '/assets/images/';

// Colors
$primary: #82c341;
$white: #ffffff;
$grey-light: #eeeeee;
$black-full: #000000;
$black-shade: #1a1a1a;
$black-dark: #111111;
$black-border: #323232;
$border-color: #505050;
$footer-text: #747474;

$mojito: linear-gradient(to right, #93f9b9, #1d976c);
$shadow: 0 15px 20px -12px rgba(0, 0, 0, 0.8);
